@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  scroll-behavior: smooth;
}
#root{
  max-width: 1620px;
  margin: 0 auto;
}
.nav{
  transition: 1s ease;
}
#root{
  position: relative;
  min-height: 100vh;
}
.scroll::-webkit-scrollbar{
  display: none;
}
.nav.active{
  border-top-color: white !important;
}
.ellipsis{
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .nav{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
  }
  .nav.active{
    background-color: white;
    color: #eb9443  /* #6060D8; */
  }
}
.body{
  position: relative;
  background-color: #F7FAFF;
}
body::-webkit-scrollbar{
  display: none;
}
.btn {
  overflow: hidden;
  position: relative;
  font-weight: 600;
  border: 1px solid white;
  transition: 1s ease;
}
.transform-center{
  transform: translate(-50%, -50%);
}
.btn::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 100px;
  background: linear-gradient(
      70deg,
      transparent,
      transparent,
      white,
      transparent,
      transparent
  );
  left: -70px;
  top: -0.4rem;
  transition: 0.5s ease-in-out;
}
.btn:hover::after {  
  left: 100%;
}
.transitions{
  transition: 1s ease !important;
}
.shadow{
  box-shadow: 0 0 8px 0 #BCCBFF;
}
.text-animation{
  animation: textAnimation 2s linear infinite;
  overflow: hidden;
}

@keyframes textAnimation {
  0% {
    width: 0;
  }
  100%{
    width: 100%;
  }
  
}

.class-grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.class-card{
  display: grid;
  grid-template-rows: 1.5fr 1fr;
}

.class-card::before{
  position: absolute;
  content: attr(data-image);
  color: red;
  width: 120%;
  left: -10px;
  top: -2rem;
  height: 70%;
  z-index: -1;
  transform: rotate(10deg);
  background: rgb(190, 189, 189);
}
.scroll-none::-webkit-scrollbar{
  display: none;
}
.SideBar-tab{
  transition: .2s ease;
  background-color: #fff;
  color: #474747!important;
}
.SideBar-tab.active{
  background:  rgb(214 144 100); /* rgb(241 165 99 1); */
   color: white; 
}
.sidebar-body{
  margin-left: 7.5rem;
}
.sidebar-body.active{
  margin-left: 16rem;
}
.fade{
  opacity: 1;
  transition: .5s ease;
  /* animation: fades 5s linear infinite; */

}
@media (max-width: 767px) {
  .sidebar-body{
    margin-left: 3.5rem;
  } 
  .sidebar-body.active{
    margin-left: 0rem;
  } 
  .lecture-sidebar {
    left: 0;
    width: 62px;
  }
  .lecture-sidebar.active {
    left: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sidebar-body{
    margin-left: 3.5rem;
  } 
  .sidebar-body.active{
    margin-left: 0rem;
  } 
  .lecture-sidebar {
    left: 0;
    width: 62px;
  }
  .lecture-sidebar.active {
    left: 0;
    width: 100%;
  }
}

@keyframes fades{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}
.box{
  background-color: #fefefe;
  box-shadow: 0 0 10px rgba(214, 143, 100, 1)/* rgba(0, 56, 255, 19%); */
}
.inner-shadow{
  box-shadow: 0 0 10px rgba(166, 180, 200, 0.75) inset;
}

.sidebar{
  width: 60px;
  transition: 1s ease-in-out;
}
.sidebar.active{
  width: 250px;
}
.main-content{
  transition: 1s ease-in-out;
  width: calc(100% - 60px);
  margin-left: 60px;
}
.sidebar.active ~ .main-content{
  width: calc(100% - 250px);
  margin-left: 250px;
}
.slider{
  right: -100%;
  transition: .5s ease-in-out;
  opacity: 50%;
}
.slider.active{
  right: 2.5rem;
  opacity: 100%;
}
.sidebar-tab{
  color: white
}
.sidebar-tab.active{
  background-color: white;
  color: #8a9deee7 /* #6060D8;  */
}
.body{
  background: linear-gradient(145deg, #FBF6FA, #def2ff, #FBF6FA);
  min-height: 100vh;
}
.shape{
  border-radius: 1rem 1rem 1rem 1rem;
  transform: skewX(-10deg);
}
/* Define the initial state of the animated elements */
.animate-on-scroll {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s, transform 0.3s;
}

/* Define the animation when elements enter the viewport */
.animate-on-scroll.show {
  opacity: 1;
  transform: translateY(0);
}
.notification{
  opacity: 0;
}
.notification.show{
  opacity: 1;
  animation: slide_notification_open 1s ease;
}
.notification.hide{
  opacity: 1;
  animation: slide_notification_close 1s ease;
}
@keyframes slide_notification_open {
  0%{
    transform: translateX(100%);
  }
  40%{
    transform: translateX(-10%);
  }
  80%{
    transform: translateX(0%);
  }
}
@keyframes slide_notification_close {
  0%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(150%);
  }
}
.shim{
  position: relative;
  overflow: hidden;
}
.shim::after{
  position: absolute;
  content: '';
  width: 100px;
  top: -5px;
  height: 150%;
  background: linear-gradient(to right, transparent, white, transparent);
  transform: rotate(10deg);
  animation: shim 1.75s ease-in-out infinite;
}

@keyframes shim {
  0%{
    left: -200px;
  }
  100%{
    left: 120%
  }
}

@media (max-width: 768px) {
  .class-grid{
    grid-template-rows: 1fr 1fr;
    background-color: red;
  }
  .body{
    margin-bottom: 4rem;
  }
  .sidebar.active ~ .main-content{
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  .sidebar ~ .main-content{
    width: calc(100% - 70px);
    margin-left: 70px;
  }
  table{
    font-size: 12px;
  }
}
@media (max-width: 428px) {
  .sidebar{
    left: 8px;
    width: calc(100% - 16px);
  }
  .sidebar.active{
    left: -100%;
  }
  .sidebar ~ .main-content{
    width: 100%;
    margin-left: 0;
  }
  .sidebar.active ~ .main-content{
    width: 100%;
    margin-left: 0;
  }
}

/*  VIDEO CONFERENCE */
.video-conference{
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* bar animation */
.progress-bar{
  animation: full_width 2s ease forwards;
}

@keyframes full_width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* students work sidebar */
.side-bar{

}
@media (max-width: 647px) {
  .side-bar{
    width: 4rem;
    left: 0;
  }
  .side-bar.active{
    width: 100%;
  }
}

.slide-absent {
  background-color: red;
  height: 100%;
  left: 0;
}

.slide-present {
  background-color: #000;
  height: 100%;
  left: 0;
  width: 20px;
  left: 10px;
}

.inputs input[type="radio"]:checked ~ .slider {
  animation: slideEffect 0.3s linear forwards;
}

@keyframes slideEffect {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}